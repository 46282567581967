import { Controller } from "stimulus";
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { attachCaptionBox } from "../javascript/lightboxes";

export default class extends Controller {

  static targets = ['trigger'];

  options = {
    gallery: this.triggerTargets,
    pswpModule: PhotoSwipe
  };

  connect() {
    const lightbox = new PhotoSwipeLightbox(this.options);

    attachCaptionBox(lightbox);
    lightbox.init();
  }
}
