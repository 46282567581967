import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";

export const enableLightboxes = (triggerSelector) => {
  triggerSelector ||= ".lightbox-trigger";

  const triggers = document.querySelectorAll(triggerSelector);
  if (triggers.length === 0) return;

  const options = {
    gallery: triggerSelector,
    pswpModule: PhotoSwipe
  };

  const lightbox = new PhotoSwipeLightbox(options);

  attachCaptionBox(lightbox);

  lightbox.init();
};

export const attachCaptionBox = (lightbox) => {
  lightbox.on("uiRegister", () => {
    lightbox.pswp.ui.registerElement({
      name: "caption",
      order: 9,
      isButton: false,
      appendTo: "root",
      onInit: (captionBox) => {
        lightbox.pswp.on("change", () => {
          const currentSlide = lightbox.pswp.currSlide.data.element;
          const caption = currentSlide.getAttribute("title");
          if (caption) {
            captionBox.innerHTML = caption;
            captionBox.classList.remove("d-none");
          } else {
            captionBox.innerHTML = "";
            captionBox.classList.add("d-none");
          }
        });
      }
    });
  });
}
