// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "@rails/actiontext";
import "channels";
import "controllers";
import "bootstrap";
import "javascript/stitches";
import "javascript/users";
import "javascript/admin";
import { enableTooltips } from "../javascript/tooltips";
import { enablePopovers } from "../javascript/popovers";
import { enableLightboxes } from "../javascript/lightboxes";
import { customizeTrixOnInit } from "../javascript/trix";

Rails.start();
ActiveStorage.start();

customizeTrixOnInit();

// Reloads triggered by broadcast stream events won't trigger the "turbo:load" callback. See "turbo:before-stream-render"
document.addEventListener("turbo:load", () => {
  enableTooltips();
  enablePopovers();
  enableLightboxes();
});

//  Mobile Safari video fix ref: https://github.com/hotwired/turbo/issues/331
document.addEventListener("turbo:render", () => {
  if (navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) {
    document.querySelectorAll("video").forEach((video) => {
      video.outerHTML = video.outerHTML; // eslint-disable-line no-param-reassign, no-self-assign
    });
  }
});
